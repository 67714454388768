<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <headerLayaut subtitle="Mapa del evento que aparecerá en la app">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialog = true"
        >{{ venueMapImage ? "Editar imagen" : "agregar imagen" }}
      </v-btn>
    </headerLayaut>
    <!-- contenido de la pagina -->
    <v-container fluid>
      <v-row class="mb-6">
        <v-col cols="12" v-if="venueMapImage">
          <v-img
            :src="venueMapImage"
            aspect-ratio="1"
            class="grey lighten-2"
            width="100%"
            height="80vh"
            style="border-radius: 10px"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      v-if="dialog"
      scrollable
      width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="dialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          {{ venueMapImage ? "Editar imagen" : "agregar imagen" }}
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-img :src="preView"> </v-img>
            </v-col>
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                v-model="fileImg"
                filled
                rounded
                prepend-icon="far fa-file-image"
                style="border-radius: 10px"
                chips
                @change="uploadImg"
                label="Imagen de mapa del evento"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="progress != 0"
            :value="progress"
          ></v-progress-linear>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
  
          <v-btn
            color="primary"
            style="border-radius: 10px"
            @click="addImageInGallery"
            >{{ venueMapImage ? "Editar" : "agregar" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import headerLayaut from "@/components/header-layaut.vue";
// import sortTemplate from "@/components/sortTemplate.vue";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import { map } from "leaflet";
export default {
  name: "galery",

  components: {
    headerLayaut,
    // sortTemplate
  },
  data() {
    return {
      dialog: false,
      dialogDeleted: false,
      fileImg: null,
      imageDeleted: null,
      progress: 0,
      Event: null,
      preView: null,
      arrayImg: [],
      imagedefaul: require("@/assets/templateImage.svg"),
      loading: false,
    };
  },
  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),
    addImageInGallery() {
      this.addNewImage().then((Response) => {
        setTimeout(() => {
          this.arrayImg = [];
          this.preView = null;
          this.fileImg = null;
          this.dialog = false;
          this.Alert_({
            text: "Imagenes agregadas correctamente",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "success",
          });
        }, 3000);
      });
    },

    addNewImage() {
      return new Promise(async (resolve, reject) => {
        if (this.fileImg) {
          this.arrayImg = [];
          const image = this.fileImg;

          let uploadTask = fb
            .app()
            .storage(`${process.env.VUE_APP_EL_OCHO_BK}-vive`)
            .ref()
            .child(`venueMap/${this.selectedEvent[`.key`]}/${image.name}`)
            .put(image);
          await uploadTask.on(
            "state_changed",
            (snapshot) => {
              // this.progress =
              //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
             console.error(error);
              this.Alert_({
                text: "Ocurrió un error inesperado, inténtelo nuevamente.",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
               // console.debug(downloadURL);
                db.collection("events").doc(this.selectedEvent[`.key`]).update({
                  venueMap: downloadURL,
                });
                this.arrayImg.push(downloadURL);
              });
            }
          );

          resolve(this.arrayImg);
        }
      });
    },

    uploadImg(e) {
      this.preView = "";
      let objectURL = URL.createObjectURL(e);
      this.preView = objectURL;
    },
  },
  computed: {
    ...mapState(["selectedEvent", "user"]),
    venueMapImage() {
      if (this.Event && this.Event.venueMap) {
        return this.Event.venueMap;
      } else {
        return null;
      }
    },
  },

  mounted() {
    this._searchTerm("");

    this.visibleSearch(false);

    this.$nextTick(() => {
      this.$binding(
        "Event",
        db.collection("events").doc(this.selectedEvent[`.key`])
      );
      this.loading = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.container-btn {
  background-color: rgba(0, 0, 0, 0.73);
  border-radius: 20px;
}
</style>
